
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.main-banner {
    display: flex;
    justify-content: space-between;
    gap: 84px;
    position: relative;
    height: auto;
    min-height: calc(100vh - 61px);
    flex-direction: column-reverse;
    align-items: center;

    @include lg {
        align-items: flex-start;
        flex-direction: row;
    }

    .form {
        z-index: 5;
        width: 100%;
        padding: 30px 24px;
        max-width: 700px;
        border-radius: 10px;
        min-height: 500px;
        background-color: white;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        position: relative;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);

        .steps-inner {
            flex-grow: 1;
        }
        h1 {
            &.step-title {
                font-size: 20px;
                font-weight: bold !important;
                margin: 0;
                @include lg {
                    font-size: 30px;
                }
            }
        }
        .step-title {
            text-align: center;
            font-size: 22px !important;
            font-weight: 700 !important;
            margin-bottom: 10px;
        }
        .label-as-step-title {
            text-align: center;
            font-size: 22px !important;
            font-weight: 700 !important;
            margin-bottom: 10px;
            margin-top: -20px;
            margin-bottom: 25px !important;
            @include lg {
                margin-left: -50px;
                margin-right: -50px;
            }
        }
        .step-description {
            text-align: center;
            font-weight: 300;
            margin-bottom: 2rem;
            font-size: 14px;
            @include lg {
                font-size: 18px;
            }
        }
        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            border-radius: 3px !important;
            background-color: $contentintelligence-purple !important;
            transition: all 0.2s ease-in-out;
            @include lg {
                // max-width: 450px !important;
            }
            &:hover {
                // background-color: $contentintelligence-purple !important;
            }
        }
        .form-field {
            // margin-top: 2rem;
            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;
            @include lg {
                // max-width: 450px;
            }
        }
        .radio-field {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;

            margin-top: 10px;

            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 500;
                        background-color: $contentintelligence-orange-hover;
                        border-color: $contentintelligence-orange;
                        color: black !important;
                    }
                }
                label {
                    border-radius: 3px;
                    border: 2px solid $contentintelligence-orange;
                    color: black;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    &:hover {
                        background-color: $contentintelligence-orange-hover;
                        // color: white;
                    }
                    span {
                        font-size: 14px !important;
                        @include md {
                            font-size: 16px !important;
                        }
                    }
                    @include md {
                        min-height: 50px;
                    }
                    svg {
                        margin-bottom: 10px;
                        width: 30px;
                        height: auto;
                    }
                }
                @include md {
                    margin: 0 10px 15px;
                    width: 60% !important;
                    margin-left: auto;
                    margin-right: auto;
                }
                &:last-child {
                    margin-bottom: 10px !important;
                }
            }
            @include lg {
                margin: 0 -10px;
            }
        }
        .checkbox-field {
            & > div {
                width: 100%;
                @include lg {
                    width: 49%;
                }
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 500;
                        background-color: $contentintelligence-orange-hover;
                    }
                }
                label {
                    margin-right: 10px;
                    border-radius: 3px;
                    border: 2px solid $contentintelligence-orange;
                    color: #000;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    & > span:first-child {
                        border-radius: 3px;
                    }
                    > span:nth-child(3) {
                        font-size: 0.8rem;
                        width: 75%;
                        text-align: start;
                    }
                    &:hover {
                        background-color: $contentintelligence-orange-hover;
                    }
                    @include md {
                        min-height: 50px;
                    }
                }
            }
        }
        .tcpa {
            margin-bottom: 10px;
        }
        @include md {
            min-width: 500px;
            padding: 40px 40px;
            margin-top: 50px;
        }
    }

    .intro {
        z-index: 2;
        margin-bottom: 120px;
        @include lg {
            margin-bottom: 0px;
            margin-top: 100px;
            h1 {
                @apply text-3xl;
            }
        }
        @apply max-w-lg;
        h1 {
            @apply text-3xl mb-6 font-bold;
        }
        p {
            @apply mb-6;
        }
        ul {
            @apply flex flex-col gap-2;

            li {
                @apply flex gap-2 mt-1;
                align-items: baseline;
            }
        }
    }

    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        background: linear-gradient(180deg, #f7decd 0%, #efe8ff 100%);
    }
    &::after {
        background-image: url("/assets/images/bgDoodle.svg");
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        background-size: 50%;
        background-repeat: repeat-x;
        background-position: bottom center;
        z-index: 2;
        @include md {
            background-size: 25%;
        }
    }
}

.step-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 345px;
}
.selling-point {
    font-size: 11px;
    color: #999;
    padding-top: 10px;
}
